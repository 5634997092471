import { EquipmentModel } from "@configuration/equipment-models/models";
import { Customer } from "@customers/customers-mgmt/models";
import { QueryFilter } from "@models/api-request.model";
import { Exportable, Exporter } from "@models/index";
import { EquipmentCategory } from '../../../configuration/equipment-categories/models/equipment-category.model';
import { EquipmentType } from "@configuration/equipment-types/models";

export class ConfigurationProfile implements Exportable {

  id:                 number = 0;
  version:            number | undefined;
  code:               string | undefined;
  name:               string | undefined;
  confirmationKey:    string | undefined;

  createdAt:          Date | undefined;

  vaildationMode:     boolean = true;

  description1:       string | undefined;
  description2:       string | undefined;
  description3:       string | undefined;
  description4:       string | undefined;
  description5:       string | undefined;
  reference1:         string | undefined;
  reference2:         string | undefined;
  reference3:         string | undefined;
  reference4:         string | undefined;
  reference5:         string | undefined;
  accesory1:          string | undefined;
  accesory2:          string | undefined;
  accesory3:          string | undefined;
  accesory4:          string | undefined;
  accesory5:          string | undefined;

  mqttConfiguration:  boolean | undefined;
  configMqttHostName: string | undefined;
  configMqttPort:     number = 0;
  configMqttUsername: string | undefined;
  configMqttPassword: string | undefined;
  configMqttTls:      number = 0;
  configMqttSequence: number = 0;

  configCommunicationLatenceConfiguration:  boolean | undefined;
  configCommunicationLatenceReports:        number = 0;
  configCommunicationLatenceGnss:           number = 0;
  configCommunicationLatenceIds:            number = 0;
  configCommunicationLatenceAccess:         number = 0;
  configCommunicationLatenceCommands:       number = 0;

  configHttpConfiguration:      boolean | undefined;
  configHttpHostName:           string | undefined;
  configHttpPort:               number = 0;
  configHttpUsername:           string | undefined;
  configHttpPassword:           string | undefined;
  configHttpTls:                number = 0;
  configHttpSendSequenceNumber: number = 0;
  configHttpServerCertificate:  string | undefined;

  configSslConfiguration:      boolean | undefined;
  configSslClientCertificate:  string | undefined;
  configSslServerCertificate:  string | undefined;
  configSslClientPrivateKey:   string | undefined;

  rfidConfiguration:      boolean | undefined;
  configRfidPooling:     number = 0;
  configRfidSensitivity: number = 0;

  configMifareConfiguration: boolean | undefined;
  configMifarePrivatekey:    string | undefined;
  configMifareSectorUid:     number = 0;
  configMifareBlockUid:      number = 0;

  configLockConfiguration:   boolean | undefined;
  configLockOperationMode:   number = 1;
  configLockAuthorizedScheduleMode:      number = 0;
  configLockAuthorizedScheduleStartHour: number = 0;
  configLockAuthorizedScheduleEndHour:   number = 0;

  configArmConfiguration: boolean | undefined;
  configArmMode:          number = 1;

  configLockAuthorizationConfiguration: boolean | undefined;
  configLockAuthorizationMode:          number = 1;

  // configEquipmentTypeConfiguration: boolean | undefined;
  configEquipmentType:              number = 0; // TODO: Revisar si se está usando y eliminar

  customerId:                       number | undefined;
  equipmentModelId:                 number | undefined;
  equipmentCategoryId:              number | undefined;
  equipmentTypeId:                  number | undefined;

  customer: Customer = new Customer();
  equipmentModel: EquipmentModel = new EquipmentModel();
  equipmentCategory: EquipmentCategory = new EquipmentCategory();
  equipmentType: EquipmentType = new EquipmentType();

  constructor(){}

}

export class ConfigurationProfileFilter implements QueryFilter {

}


export class ConfigurationProfileExporter implements Exporter  {

  code;
  name;


  constructor() {

    this.code= {
      nameToShow: 'Código'
    };
    this.name = {
      nameToShow: 'Nombre'
    };
  }
}




